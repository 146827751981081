var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "add-new-user-sidebar",
      "visible": _vm.isAddNewUserSidebarActive,
      "bg-variant": "white",
      "sidebar-class": "sidebar-lg",
      "shadow": "",
      "backdrop": "",
      "no-header": "",
      "right": ""
    },
    on: {
      "hidden": _vm.resetForm,
      "change": function change(val) {
        return _vm.$emit('update:is-add-new-user-sidebar-active', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" Add New User ")]), _c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1), _c('validation-observer', {
          ref: "refFormObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var handleSubmit = _ref2.handleSubmit;
              return [_c('b-form', {
                staticClass: "p-2",
                on: {
                  "submit": function submit($event) {
                    $event.preventDefault();
                    return handleSubmit(_vm.onSubmit);
                  },
                  "reset": function reset($event) {
                    $event.preventDefault();
                    return _vm.resetForm.apply(null, arguments);
                  }
                }
              }, [_c('validation-provider', {
                attrs: {
                  "name": "Full Name",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Full Name",
                        "label-for": "full-name"
                      }
                    }, [_c('b-form-input', {
                      attrs: {
                        "id": "full-name",
                        "autofocus": "",
                        "state": _vm.getValidationState(validationContext),
                        "trim": "",
                        "placeholder": "John Doe"
                      },
                      model: {
                        value: _vm.userData.fullName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "fullName", $$v);
                        },
                        expression: "userData.fullName"
                      }
                    }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('validation-provider', {
                attrs: {
                  "name": "Username",
                  "rules": "required|alpha-num"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Username",
                        "label-for": "username"
                      }
                    }, [_c('b-form-input', {
                      attrs: {
                        "id": "username",
                        "state": _vm.getValidationState(validationContext),
                        "trim": ""
                      },
                      model: {
                        value: _vm.userData.username,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "username", $$v);
                        },
                        expression: "userData.username"
                      }
                    }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('validation-provider', {
                attrs: {
                  "name": "Email",
                  "rules": "required|email"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Email",
                        "label-for": "email"
                      }
                    }, [_c('b-form-input', {
                      attrs: {
                        "id": "email",
                        "state": _vm.getValidationState(validationContext),
                        "trim": ""
                      },
                      model: {
                        value: _vm.userData.email,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "email", $$v);
                        },
                        expression: "userData.email"
                      }
                    }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('validation-provider', {
                attrs: {
                  "name": "Contact",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Contact",
                        "label-for": "contact"
                      }
                    }, [_c('b-form-input', {
                      attrs: {
                        "id": "contact",
                        "state": _vm.getValidationState(validationContext),
                        "trim": ""
                      },
                      model: {
                        value: _vm.userData.contact,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "contact", $$v);
                        },
                        expression: "userData.contact"
                      }
                    }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('validation-provider', {
                attrs: {
                  "name": "Company",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Company",
                        "label-for": "company"
                      }
                    }, [_c('b-form-input', {
                      attrs: {
                        "id": "company",
                        "state": _vm.getValidationState(validationContext),
                        "trim": ""
                      },
                      model: {
                        value: _vm.userData.company,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "company", $$v);
                        },
                        expression: "userData.company"
                      }
                    }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('validation-provider', {
                attrs: {
                  "name": "Country",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Country",
                        "label-for": "country",
                        "state": _vm.getValidationState(validationContext)
                      }
                    }, [_c('v-select', {
                      attrs: {
                        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                        "options": _vm.countries,
                        "clearable": false,
                        "input-id": "country"
                      },
                      model: {
                        value: _vm.userData.country,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "country", $$v);
                        },
                        expression: "userData.country"
                      }
                    }), _c('b-form-invalid-feedback', {
                      attrs: {
                        "state": _vm.getValidationState(validationContext)
                      }
                    }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('validation-provider', {
                attrs: {
                  "name": "User Role",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "User Role",
                        "label-for": "user-role",
                        "state": _vm.getValidationState(validationContext)
                      }
                    }, [_c('v-select', {
                      attrs: {
                        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                        "options": _vm.roleOptions,
                        "reduce": function reduce(val) {
                          return val.value;
                        },
                        "clearable": false,
                        "input-id": "user-role"
                      },
                      model: {
                        value: _vm.userData.role,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "role", $$v);
                        },
                        expression: "userData.role"
                      }
                    }), _c('b-form-invalid-feedback', {
                      attrs: {
                        "state": _vm.getValidationState(validationContext)
                      }
                    }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('validation-provider', {
                attrs: {
                  "name": "Plan",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(validationContext) {
                    return [_c('b-form-group', {
                      attrs: {
                        "label": "Plan",
                        "label-for": "plan",
                        "state": _vm.getValidationState(validationContext)
                      }
                    }, [_c('v-select', {
                      attrs: {
                        "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                        "options": _vm.planOptions,
                        "reduce": function reduce(val) {
                          return val.value;
                        },
                        "clearable": false,
                        "input-id": "plan"
                      },
                      model: {
                        value: _vm.userData.currentPlan,
                        callback: function callback($$v) {
                          _vm.$set(_vm.userData, "currentPlan", $$v);
                        },
                        expression: "userData.currentPlan"
                      }
                    }), _c('b-form-invalid-feedback', {
                      attrs: {
                        "state": _vm.getValidationState(validationContext)
                      }
                    }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
                  }
                }], null, true)
              }), _c('div', {
                staticClass: "d-flex mt-2"
              }, [_c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(255, 255, 255, 0.15)',
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "mr-2",
                attrs: {
                  "variant": "primary",
                  "type": "submit"
                }
              }, [_vm._v(" Add ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(186, 191, 199, 0.15)',
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                attrs: {
                  "type": "button",
                  "variant": "outline-secondary"
                },
                on: {
                  "click": hide
                }
              }, [_vm._v(" Cancel ")])], 1)], 1)];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }